.singlePage {
  padding: 50px 0;
  background: #fff;
}
.singlePage .right {
  margin-top: 30px;
}
.singlePage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.singlePage h1 {
  font-size: 30px;
  font-weight: 500;
}
.singlePage p {
  margin: 20px 0;
  font-size: 18px;
  line-height: 2;
  text-transform: capitalize;
}
.singlePage .buttons {
  display: flex;
  justify-content: flex-end;
}
.singlePage .button {
  margin-left: 20px;
  border: none;
}
