.bold-text {
  font-family: Poppins, sans-serif;
  font-size: 40px;
  line-height: 50px;
}

.rich-text-block {
  font-family: Quicksand, sans-serif;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

h2 {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.bold-text-2 {
  font-family: Poppins, sans-serif;
  font-size: 40px;
  line-height: 50px;
}

.bold-text-3,
.bold-text-4,
.bold-text-5,
.bold-text-6,
.bold-text-7,
.bold-text-8,
.bold-text-9,
.bold-text-10,
.bold-text-11,
.bold-text-12,
.bold-text-13,
.bold-text-14,
.bold-text-15,
.bold-text-16,
.bold-text-17,
.bold-text-18,
.bold-text-19,
.bold-text-20,
.bold-text-21,
.bold-text-22,
.bold-text-23,
.bold-text-24,
.bold-text-25,
.bold-text-26,
.bold-text-27,
.bold-text-28,
.bold-text-29,
.bold-text-30,
.bold-text-31,
.bold-text-32,
.bold-text-33,
.bold-text-34 {
  font-size: 28px;
}

.bold-text-35 {
  font-family: Poppins, sans-serif;
  font-size: 40px;
}

.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6,
.heading-7,
.heading-8,
.heading-9,
.heading-10,
.heading-11,
.heading-12,
.heading-13,
.heading-14,
.heading-15,
.heading-16 {
  font-size: 28px;
}
