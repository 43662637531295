@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');



body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

.preferences-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Create two columns of equal width */
  column-gap: 20px;
  /* Space between columns */
  row-gap: 30px;
  /* Space between rows */
  margin-top: 40px;
}

.body-blur {
  pointer-events: none;
  filter: blur(2px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollnon::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollnon {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Tailwind CSS for Google Maps Autocomplete Dropdown */
.pac-container {
  border-radius: 0.5rem;
  /* equivalent to rounded-md */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  /* equivalent to shadow-lg */
  border: none;
  /* border-none */
  font-family: "Inter var", sans-serif;
  /* Assuming Inter is the default */
}

.pac-item {
  padding: 0.5rem 1rem;
  /* equivalent to p-2 px-4 */
  cursor: pointer;
  /* cursor-pointer */
  font-size: 1rem;
  /* text-base */
}

.pac-item:hover {
  background-color: #f9fafb;
  /* equivalent to bg-gray-100 */
}

.pac-item-query {
  font-weight: bold;
  /* font-bold */
}

.pac-icon {
  display: none;
  /* hidden - to hide the default Google Maps icons */
}

.modal-backdrop {
  position: fixed;
  /* Use fixed positioning */
  top: 0;
  left: 0;
  width: 100vw;
  /* Full viewport width */
  height: 100vh;
  /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  display: flex;
  justify-content: center;
  /* Center the child horizontally */
  align-items: center;
  /* Center the child vertically */
  z-index: 1000;
  /* High z-index to be on top of other content */
}

.modal-dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: for better visibility */
  z-index: 1001;
  /* Ensures it is above the backdrop */
}

.preferences-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* Space between buttons */
  margin-bottom: 20px;
}

.preference-button {
  background-color: #fff;
  border: 2px solid #ccc;
  /* Light grey border */
  color: #333;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.preference-button:hover,
.preference-button.selected {
  background-color: #f0f0f0;
  /* Slightly darker on hover */
  border-color: #bbb;
}

.preference-button.others-button {
  background-color: #ff0066;
  /* Pink button for 'Others' */
  color: #fff;
}

.preference-button.others-button:hover {
  background-color: #cc0055;
  /* Darker pink on hover */
}

.hidden {
  display: none;
}

.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #ccc;
}

.tag {
  margin: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 15px;
  border: 1px solid #ccc;
  cursor: pointer;
  user-select: none;
}

.tag:hover {
  background-color: #e0e0e0;
}

.dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
}

.tag {
  background-color: #f2f2f2;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.remove-tag {
  display: inline-block;
  padding-left: 10px;
  color: #ff0000;
  cursor: pointer;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.slider-container {
  position: relative;
  margin: 20px 0;
}

.slider {
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff0066;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff0066;
  cursor: pointer;
}

.price-display {
  text-align: center;

  font-size: 18px;
  color: #333;
}

html {
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ed5272;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  /* Color of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ed5272;
  /* Color of the handle */
}

/* Add this inside your CSS file */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-50% + 1rem));
  }

  /* Scroll to the left */
}

.scrolling-logos {
  white-space: nowrap;
  overflow: hidden;
}

.scroll-animation {
  display: flex;
  animation: scroll 30s linear infinite;
}

.scroll-animation:hover {
  animation-play-state: paused;
}

/* Add this class in your global styles or a CSS/SCSS file */
.underline-transition::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  /* Adjust the thickness of the underline */
  background: currentColor;
  /* Use the text color for the underline */
  transition: width 0.5s ease;
  /* Adjust time as needed */
  margin-top: 0.5rem;
  /* Adjust the gap between the text and underline */
}

.underline-transition:hover::after,
.underline-transition:focus::after {
  width: 100%;
}

.hover\:text-pink-500:hover {
  color: #ec4899;
  /* Tailwind's pink-500 color */
}

/* Add these styles in your global stylesheet (e.g., index.css or App.css) */
/* Add these styles to your CSS file */
.dropdown-arrow::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-left: 8px;
  /* Space between the text and the arrow */
  border-top: 2px solid #ffffff;
  /* Top side of the V */
  border-right: 2px solid #ffffff;
  /* Right side of the V */
  width: 8px;
  /* Width of the V */
  height: 8px;
  /* Height of the V */
  transform: translateY(-2px) rotate(135deg);
  /* Rotate the border to form a V */
  transform-origin: center;
}

.dropdown-button.open .dropdown-arrow::after {
  transform: translateY(1px) rotate(-45deg);
  /* Rotates the arrow to point up */
}

/* Add this to your global stylesheet */
input::placeholder {
  color: white;
  opacity: 1;
  /* Override Firefox's default placeholder opacity */
}

#product-container>div {
  transition: transform 0.7s ease-out;
  /* You can adjust the time here for the speed of zooming out */
}

/* Add this to your stylesheet */

.form-radio {
  color: red;
  background-color: #ec4899;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.active {
  background-color: #ed5272;
  /* Set your desired active background color here */
}

.video-js {
  background-color: transparent;
}

@media (max-width: 768px) {
  .card-hovered.hover .card-content {
    position: absolute;
    left: 16px;
    top: 37px;
    transition: all 1s ease-in;
  }

  .card-hovered.hover .card-content-buy {
    position: absolute;
    top: 10px;
    left: 20px;
    transition: all 1s ease-in;
  }

  .card-hovered.hover .card-img {
    opacity: 100;
    transition: all 1s ease-in;
  }

  .card-hovered.hover .icon-div {
    opacity: 0;
    transition: all 1s ease-in;
  }
}

.analyze-card:hover .card-content {
  position: absolute;
  left: 16px;
  top: 37px;
  transition: all 0.3s ease-in;
}

.analyze-card:hover .card-content-buy {
  position: absolute;
  top: 10px;
  left: 20px;
  transition: all 0.3s ease-in;
}

.analyze-card:hover .card-img {
  opacity: 100;
  transition: all 0.3s ease-in;
}

.analyze-card:hover .icon-div {
  opacity: 0;
  transition: all 0.3s ease-in;
}

.years-of-investment::-webkit-outer-spin-button,
.years-of-investment::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* slider spacing */
.slick-slide>div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.score-animation {
  display: inline-block;
  animation: slideUp 0.3s linear;
}

@keyframes fillAnimation {
  from {
    fill-opacity: 0;
  }

  to {
    fill-opacity: 1;
  }
}

.score-fill {
  animation: fillAnimation 4s ease;
}

.mapboxgl-canvas {
  border-radius: 20px !important;
}


/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #FF5B7D #888;
  /* For Firefox */
}

/* For WebKit-based browsers (e.g., Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  /* Color of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the thumb */
  border-radius: 10px;
  /* Roundness of the thumb */
  border: 2px solid #fff;
  /* Space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the thumb when hovered */
}